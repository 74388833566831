import React from "react";
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import styled from 'styled-components'

interface IOProps {
    upfunc:()=>void;
    downfunc:()=>void;
}

const IncrementDecrementComp:React.FC<IOProps> = ({upfunc, downfunc}) => {

    return (
        <MainContainer>
            <IoIosArrowUp onClick={upfunc} fontSize="24px"/>
            <IoIosArrowDown onClick={downfunc} fontSize="24px" />
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export default IncrementDecrementComp