import React, {useState, useEffect} from 'react'
import styled, { keyframes } from 'styled-components'
import ProfileComp from '../components/Profile'

interface Prop{
}
const Profile : React.FC<Prop>=({})=>{
  return(
    <MainContainer>
        <Column>
            <ProfileComp />
        </Column>
    </MainContainer>
  );
}


const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 85vh;
    justify-content: flex-start;
    align-items: center;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
`;
const Row = styled.div`
    display: flex;
`;
const Header = styled.div`
    font-size: 30px;
    font-weight: 700;
`;
export default Profile