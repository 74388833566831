import React, { FC, useEffect, useState, useCallback } from 'react'
import styled, { keyframes } from 'styled-components'
import MenuLink from './MenuLink';
import { userContext } from "../../usercontext"
import MenuButton from './MenuButton';
import { AiOutlineMenu } from 'react-icons/ai'
import img from "../../assets/icon.png";

interface Props{
  history?: any;
}


const Navbar: React.FC<Props> = (props) => {
  const [user, setUser] = useState<any>(null);
  const [hidemenu, sethidemenu] = useState(true);
  const [firstname, setfirstname] = useState('')

  useEffect(() => {
    // console.log(`Here navbar2 ${user}`)
}, [user])


  useEffect(() => {
    const subscription = userContext.user.subscribe((x: any) => {
      let user: any = x //JSON.parse(JSON.stringify(x)); 
      // console.log(JSON.parse(JSON.stringify(x)))
      // console.log(user.status)
      if(!user || user.status !=200){
        setfirstname('')
      }else{
        setfirstname(user.firstname)
      }
      setUser(user)
    })
    return () => subscription.unsubscribe()
  }, [])

  const hidemobilemenu = () => {
    if(!hidemenu){
      sethidemenu(true)
    }
  }

  const logout = () => {
    userContext.logout();
  }


  return (
    <>
      <StyledNavBar onClick={hidemobilemenu}>
        <StyledNavBarInner>
          <Logo>
              <Link href="/">
                <Image src={img} width="100" height={"100"} style={{cursor:"pointer"}} alt={""}/>
                <LogoText>ZappletZ</LogoText>
              </Link>


          </Logo>
          {!hidemenu?
          <StyledBackdrop>
            <MobileMenuContainer >
              <Image src={img} width="75" height={"75"} style={{cursor:"pointer"}} alt={"zappletz.com"}/>
              {/* <LogoTextMobile>MiAmigo</LogoTextMobile> */}
              <MenuLink key={`mmenuhome`} link={'/'} text={'Home'}></MenuLink>
              {/* <MenuLink key={`mmenutools`} link={'/frequently-asked-questions'} text={'faq'}></MenuLink> */}
              {user && user.status==200?
                <>
                  {/* <MenuLink key={`mmenuprofile`} link={'/profile'} text={'Profile'} ></MenuLink> */}
                  <MenuLink key={`mmenudashboard`} link={'/password-manager'} text={'Passwords'} ></MenuLink>
                  {/* <MenuLink key={`mmenulogout`} link={'/logout'} text={'Logout'} ></MenuLink> */}
                  <MenuButton key={`menulogout`} text={'Logout'} handleClick={logout}></MenuButton>
                  <MenuLink key={`menuprofile1`} link={'/profile'} text={`Hi ${user.firstname?user.firstname.substring(0,8):""} !`} ></MenuLink>

                </>:
                <>
                  <MenuLink key={`mmenutools`} link={'/frequently-asked-questions'} text={'FAQ'}></MenuLink>
                  <MenuLink key={`mmenulogin`} link={'/login'} text={'Login'} ></MenuLink>
                  <MenuLink key={`mmenuregister`} link={'/register'} text={'register'} ></MenuLink>
                </>

              }
            </MobileMenuContainer>
          </StyledBackdrop>
            :<></>
          }
            <MenuContainer>
              <MenuLink key={`menuhome`} link={'/'} text={'Home'}></MenuLink>
              {/* <MenuLink key={`menutools`} link={'/frequently-asked-questions'} text={'faq'}></MenuLink> */}
              {user && user.status==200?
                <div style={{display:'flex', alignItems:'center'}}>
                  <MenuLink key={`menudashboard`} link={'/password-manager'} text={'Passwords'} ></MenuLink>
                  <MenuButton key={`menulogout`} text={'Logout'} handleClick={logout}></MenuButton>
                  <MenuLink key={`menuprofile`} link={'/profile'} text={`Hi ${user.firstname?user.firstname.substring(0,8):""} !`} ></MenuLink>
                  {/* <div style={{fontSize: "14px", fontWeight:500, cursor:"pointer"}}>{`Hi ${user.firstname} !`}</div> */}
                </div>
                :
                <>
                  <MenuLink key={`menutools`} link={'/frequently-asked-questions'} text={'FAQ'}></MenuLink>
                  <MenuLink key={`menulogin`} link={'/login'} text={'Login'} ></MenuLink>
                  <MenuLink key={`menuregister`} link={'/register'} text={'register'} ></MenuLink>
                </>

              }
            </MenuContainer>
            <MenuIcon>
              <MenuIcon1 onClick={()=>props.history.push("/")}  style={{alignItems:'center'}}>
              </MenuIcon1>
              <MenuIcon1 onClick={()=>sethidemenu(!hidemenu)}>
                <AiOutlineMenu size={"35px"}/>
              </MenuIcon1>
            </MenuIcon>
          
        </StyledNavBarInner>
      </StyledNavBar>
    </>
  )
}

const slideIn = keyframes`
  0% {
    transform: translateX(-100%)
  }
  100% {
    transform: translateX(0);
  }
`

const Image = styled.img`
  // &:hover{
  //   width: 80px;
  //   height: 80px;
  // }
`;

const MenuIcon = styled.div`
  display: none;
  width: 100%;
  @media (max-width: 900px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
`;

const MenuIcon1 = styled.div`
  display: flex;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #000000;
  @media (max-width: 900px) {
    display: none;
  }
`

const MobileMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-text: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: white;
  color: #000;
  width: 50%;
  padding: 25px;
  transition: ease 0.5s;
  animation: ${slideIn} 0.3s forwards ease-out;
  `;

const StyledNavBar = styled.div`
  align-items: center;
  color: #000000;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25)
`
const StyledNavBarInner = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  color: #000000;
`
const StyledBackdrop = styled.div`
  background-color: #f0f0f0;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
`;

const Logo = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: flex-start;
  @media(max-width:900px){
    // display: none;
  }
`

const LogoText = styled.div`
  display: flex;
  flex: 2;
  color: #000000;
  // align-items: center;
  // justify-content: flex-start;
  padding: 0 10px;
  @media(max-width:900px){
    font-size: 30px;
    // display: none;
  }
`

const LogoTextMobile = styled.div`
  font-size: 25px;
  font-weight: 700;
  padding-left: 10px;
`

const IconDiv = styled.div`
  display: none;
  @media (max-width: 770px) {
    display: block;
  }
`

const LogoAndImgContainer = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`
const Link = styled.a`
  display: flex;
  align-items: center;
  font-size: 45px;
`;
export default Navbar
