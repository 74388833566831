import { useEffect, useState } from "react";
import styled from "styled-components";
import MessageModal from "../components/MessageModal";
import Button from "../components/Button";
import { userContext } from '../usercontext';
import Profile from "../components/Profile";
import Encrypt from "../components/Encrypt";
import Decrypt from "../components/Decrypt";
import Billing from "../components/Billing";
import LoadingComponent from "../components/LoadingComponent";
import BackupData from "../components/BackupData";

interface Props{
  history?: any;
}

const Dashboard: React.FC<Props> = (props) => {

  const [ user, setUser ] = useState<any>("")
  const [ showitem, setshowitem ] = useState("2")
  

  const [modalheader, setmodalheader] = useState('')
  const [modalmessage, setmodalmessage] = useState('')
  const [modalprogress, setmodalprogress] = useState(false)  
  const [modalstate, setmodalstate] = useState(false)

  const openModal = (header: string, message: string, progress?: boolean) => {
    setmodalheader(header)
    setmodalmessage(message)
    setmodalstate(true)
    setmodalprogress(progress?progress: false)
    
  }

  const showComp = () => {
      switch(showitem){
      // case "0":
      //   return <Profile />
      case "1":
        return <Encrypt />
      case "2":
        return <Decrypt />  
      case "3":
        return <Billing />
      case "4":
        return <BackupData />
      default:
        return <></>
    }
  }


  useEffect(() => {
    
  }, [showitem])

  useEffect(() => {
    const subscription = userContext.user.subscribe((x:any) => setUser(x))
    return () => subscription.unsubscribe()
  }, [])

  useEffect(()=>{
    if(!user)return;
    if(user.status!==200){
      props.history.push("/login")
    }
  })


  return (
    <>
      <MainContainer style={{flexDirection: 'column'}}>
          <MessageModal header={modalheader} message={modalmessage} state={modalstate} handleClose={()=> setmodalstate(false)} progress={modalprogress} />
          {/* <Header>Dashboard</Header> */}
          <Row>
          {user&&user.status==200?<>

            <ColumnItems style={{flex:0.5}}>

              <Button text="Retrieve" handleClick={()=>setshowitem("2")} backgroundColor={showitem=="2"?"#e2d4f6":""} width="75px"/>
              <Button text="Save" handleClick={()=>setshowitem("1")} backgroundColor={showitem=="1"?"#e2d4f6":""} width="75px"/>
              <Button text="Backup" handleClick={()=>setshowitem("4")} backgroundColor={showitem=="4"?"#e2d4f6":""} width="75px"/>

              

            </ColumnItems>
            <Column style={{flex:5}}>
              {
                showComp()
              }
            </Column>
            </>:
            <>
              <LoadingComponent />  
            </>
          }
        </Row>
      </MainContainer>
    </>
  );
};


const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 85vh;
  justify-content: flex-start;
  align-items: flex-start;
  align-items: center;
  // padding: 20px;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  // margin: 10px;
  // padding: 10px;
  @media (max-width: 770px) {
    flex-direction: column;
  }
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  padding: 10px;
  @media (max-width: 770px) {
    padding: 20px 10px;
  }
`

const ColumnItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  padding: 50px;
  color: #000;
  border-right: 1px solid black;
  @media (max-width: 770px) {
    padding: 20px 50px;
    border-right: none;
  }
`

const Header = styled.div`
    padding: 10px;
    font-weight: 700;
    font-size: 40px;
    justify-content: flex-end;
    color: black;
`;

const SubHeader = styled.div`
    padding: 10px;
    font-weight: 700;
    font-size: 30px;
    justify-content: flex-end;
    color: black;
`;


export default Dashboard;
