import React from "react";
import styled from "styled-components";

interface OwnProps {
  history: any;
}
type Props = OwnProps;

const Terms = (props: Props) => {
  return (
    <>

      <MainContainer>
        <Section>
          <Header>Terms of Service</Header>

          <Paragraph>
          These Website Terms of Use (&quot;Terms of Use&quot; or &quot;Terms of Service&quot; or &quot;Terms&quot;) are applicable to the websites ZappletZ  referred to as &quot;ZappletZ &quot; or &quot;website&quot;, , and its affiliates and subsidiaries (collectively, &quot;we”, &quot;us”, &quot;our”, ZappletZ.com”, or the &quot;Company”), including www.ZappletZ.com and any other website where these Terms of Use are posted (&quot;Websites”). The Website Terms of Use are applicable to all services, applications and tools provided available on ZappletZ.com or www.zappletz.com or any other mobile device platform or store, collectively referred as services. These Terms are a legally binding agreement between you and your representatives and your heirs (collectively, &quot;you&quot;, &quot;User&quot; or &quot;Users&quot;) and ZappletZ.com
          </Paragraph>


          <Title>1. Accepting the Terms and Changes to these Terms</Title>
          <Paragraph>
            By accessing or using the Services, you are agreeing to these Terms of Use and entering into a legally binding agreement with us. If you do not agree to the Terms of Use, including the binding arbitration clause and class action waiver contained in Section below, you may not use the Services or create an account.
          </Paragraph>
          <Paragraph>
            We reserve the right to change these Terms which will be updated on this page. Please regularly check for any updates as we cannot gaurantee notification of these updates to you.
          </Paragraph>
          
          <Title>2. Eligibility; Accounts and Registration.</Title>
          <Paragraph>
            You must be at least 18 years of age to use the Services. By
            agreeing to these Terms of Use, you represent and warrant that: (a)
            you are at least 18 years of age; (b) you have not previously been
            suspended or removed from the Services; and (c) your registration
            and your use of the Services is in compliance with all applicable
            laws and regulations. To access some features of the Services, you
            may be required to register for an account and agree to a Product&apos;s
            Terms, to the extent applicable to that service, which may be
            incorporated herein. When you register for an account you will be
            asked to provide us with some information about yourself, such as
            email address, phone number, or other contact information. You agree
            that the information you provide is accurate and that you will keep
            it accurate and up-to-date at all times. When you register, you will
            be asked to provide a password. You are solely responsible for
            maintaining the confidentiality of your account and password, and
            you are responsible for all actions taken via your account. You may
            not share your user account(s) with others. Unless you have entered
            into a commercial agreement with us permitting you to use the
            Services for transactions on behalf of another person, you may only
            use the Services for transactions on your own behalf.
          </Paragraph>

            <Title>3. Use of the Services.</Title>
          <Paragraph>
            As long as you comply with these Terms of Use, ZappletZ grants you a
            non-exclusive, limited, revocable, personal, non-transferable
            license to use the Services. If you use the Services for a
            Professional Use, you represent and warrant that you have obtained all
            required authorizations and consents from your client. Except as
            expressly stated herein, these Terms of Use do not provide you with
            a license to use, reproduce, distribute, display or provide access
            to any portion of the Services on third-party web sites or
            otherwise. The Services may include software for use in connection
            with the Services.
          </Paragraph>

          {/* 
          <Title>Mobile Applications.</Title>

          <Paragraph>
              To use any mobile application feature of the Services (each, an “App”), you must have a compatible mobile device. We do not warrant that any App will be compatible with your mobile device. You may use mobile data in connection with an App and may incur additional charges from your wireless provider for using an App. You agree that you are solely responsible for any applicable charges. We may update any app and may automatically electronically update the version of any App that you have installed on a mobile device. You consent to all automatic upgrades, and understand that these Terms of Use will apply to all updated versions of an App. Any third-party open source software included in an App is subject to the applicable open source license and may be available directly from the creator under an open source license. These Terms of Use do not apply to your use of software obtained from a third-party source under an open source license.
          </Paragraph> */}

            <Title>
              4. Prohibited Use.(BY USING THE SERVICES, YOU AGREE NOT TO)
            </Title>
            <ul style={{lineHeight:2}}>
              <li>
                reproduce, modify, distribute, display or otherwise provide
                access to, create derivative works from, decompile, disassemble,
                or reverse engineer any portion of the Services, except as
                explicitly permitted by any Product&apos;sTerms to the extent
                applicable to that product&apos;sServices
              </li>

              <li>
                remove or modify any copyright or other intellectual property
                notices that appear in the Services
              </li>

              <li>
                use the Services in any way that is unlawful, or harms the
                ZappletZ , its service providers, suppliers, affiliates, or any
                other user
              </li>

              <li>
                use the Services in any way to discriminate against any
                individual or class of individuals protected under federal,
                state or local laws, or which may have a discriminatory impact
                against any individual or class of individuals, or which
                otherwise promotes illegal, racist or discriminatory activities
                or outcomes
              </li>

              <li>
                distribute or post spam or other unsolicited messages, chain
                letters, pyramid schemes, or similar communications through the
                Services
              </li>

              <li>
                impersonate another person, misrepresent your affiliation with
                another person or entity, or make any representation to any
                third party under false pretenses
              </li>

              <li>
                reproduce, publicly display, or otherwise make accessible on or
                through any other website, application, or service any reviews,
                ratings, or profile information about services, or other data or content available through the Services, except as explicitly permitted by us for a
                particular portion of the Services
              </li>

              <li>
                upload invalid data, viruses, worms, or other software agents to
                the Services
              </li>

              <li>
                post, reproduce, publicly display, or otherwise make accessible
                any content, which we, in our sole judgement and discretion,
                consider illegal, offensive or objectionable including without
                limitation content that harasses, discriminates, demeans,
                threatens or disparages any individual or class of individuals
              </li>

              <li>
                interfere with, or compromise the system integrity or security
                of the Services, or otherwise bypass any measures we may use to
                prevent or restrict access to the Services
              </li>

              <li>
                conduct automated queries (including screen and database
                scraping, spiders, robots, crawlers, bypassing &quot;captcha” or
                similar precautions, or any other automated activity with the
                purpose of obtaining information from the Services) on the
                Services
              </li>

              <li>
                use any of the ZappletZ trademarks as part of your screen name
                or email address on the Services
              </li>

              <li>
                access or use any of the Services to develop competitive
                products or services; or
              </li>

              <li>
                attempt to, or permit or encourage any third party to, do any of
                the above.
              </li>
            </ul>

            <Title>5. Fees.</Title>
          <Paragraph>
            Generally, you may be required to pay fees to access certain
            features of the Services. All fees are in U.S. dollars and are
            non-refundable. ZappletZ reserves the right to change its fee for its services. If we change the fees for all or part of any of the
            Services, including by adding fees or charges, we will provide you
            advance notice of those changes. If you do not accept the changes,
            we may discontinue providing the applicable part of the Services to
            you. Our authorized third-party payment processors will charge the
            payment method you specified at the time of purchase or as agreed to
            as part of the selected Service. You authorize us to charge all fees
            as described in these Terms of Use for the Services you select to
            that payment method. If you pay any fees with a credit card, we may
            seek pre-authorization of your credit card account before your
            purchase to verify that the credit card is valid and has the
            necessary funds or credit available to cover your purchase.
            <br />
            <br />
            Subscriptions. The Services may include features that allow for
            automatically recurring payments for periodic charges (&quot;Subscription
            Service”). If you decide to activate a Subscription Service, you
            authorize us to periodically charge, on a going-forward basis and
            until cancellation of recurring payments
            all accrued sums on or before the payment due date for the accrued
            sums. The subscription will continue unless and until you cancel
            your subscription, or we terminate it. You must cancel your
            Subscription Service before it renews in order to avoid billing of
            the next periodic subscription fee to your account. We will bill the
            periodic subscription fee to the payment method you provide to us
            during registration (or to a different payment method if you change
            your payment information). We may change the subscription fee for
            any subsequent subscription period but will provide you advance
            notice of any increase before it applies. Unless otherwise stated in
            your Product&apos;sTerms, you may cancel a Subscription Service through
            on your account page (https://ZappletZ.com/dashboard).
          </Paragraph>
          
          <Title>6. User Materials.</Title>
          <Paragraph>
            UGC Definition; License Grant. Certain portions of the Services may
            allow users to upload or otherwise provide the ZappletZ images,
            photos, video, data, text, listings, and other content (&quot;User
            Materials”). Absent any additional terms provided in your Product&apos;s
            Terms,by uploading or otherwise providing User Materials to the
            Services, you grant us an irrevocable, perpetual, royalty-free
            worldwide license to: (i) use, copy, distribute, transmit, publicly
            display, publicly perform, reproduce, edit, modify, prepare
            derivative works of or incorporate into other works, and translate
            your User Materials, in connection with the Services or in any other
            media; and (ii) sublicense these rights, to the maximum extent
            permitted by applicable law. We will not pay you for your User
            Materials or to exercise any rights related to your User Materials
            set forth in the preceding sentence. We may remove or modify your
            User Materials at any time. You are solely responsible for all User
            Materials made through your user account(s) on the Services or that
            you otherwise make available through the Services. For all User
            Materials, you represent and warrant that you are the creator and
            owner of the User Materials, or have the necessary licenses, rights,
            consents, and permissions (including all permissions required under
            applicable privacy and intellectual property law) to authorize
            ZappletZ Agents and other users to access and use your User
            Materials as necessary to exercise the licenses granted by you under
            these Terms of Use. We will not use client contact information that
            is uploaded by agents into the ZappletZ portal for any purpose,
            unless that client contact information is otherwise provided to us
            directly by the client.
            <br />
            <br />
            UGC Disclaimer. We are under no obligation to edit or control your
            User Materials or the User Materials of any other User and will not
            be in any way responsible or liable for any User Materials. We may,
            however, at any time and without prior notice, screen, remove, edit,
            or block any User content on the Services, including User Materials,
            that in our sole judgment violates these Terms of Use or we find
            otherwise objectionable, including any violation of our Good
            Neighbor Policy, described in Section 13(B). You understand that
            when using the Services, you may be exposed to User Materials of
            other users and acknowledge that User Materials may be inaccurate,
            offensive, indecent, or objectionable. You agree to waive, and do
            waive, any legal or equitable right or remedy you may have against
            us with respect to User Materials. We expressly disclaim any and all
            liability in connection with User Materials. If notified by a user
            or content owner that User Materials allegedly do not conform with
            these Terms of Use, we may investigate the allegation and determine
            in our sole discretion whether to remove those User Materials, which
            we reserve the right to do at any time and without notice. For more
            information on our handling of infringing content, please see
            Section 11 below.
          </Paragraph>

            <Title>7. 3rd Party/Linked Services/Sent information.</Title>
          <Paragraph>
            The Services include links to third-party products, services, and
            websites, as well as materials provided by third parties, and may
            include functionality that allows for the distribution of your User
            Materials or your personal information (collectively, your &quot;User
            Information”) to third parties not under the our control (each, a
            &quot;Third-Party Provider”). Third-Party Providers are solely
            responsible for their services. You are responsible for your use and
            submission of User Information to any third party, and your dealings
            or business conducted with any third party arising in connection
            with the Services are solely between you and such third party. Your
            use of third-party sites, services, or products may be subject to
            associated third-party terms of use and privacy policies or other
            agreements, which you are solely responsible for complying with. We
            do not endorse, and take no responsibility for such products,
            services, Web sites, and materials, or a Third-Party Provider&apos;suse
            of your User Information. By using a tool that allows for User
            Information to be transferred, you agree that we may transfer the
            applicable User Information or other information to the applicable
            third-parties, which are not under our control. If you submit a
            contact form or otherwise indicate your interest in contacting a
            Third-Party Provider, you may receive telemarketing calls from the
            Third-Party Provider using the contact information you provided.
            Third-Party Providers may keep your contact information and any
            other information received by the Third-Party Provider in processing
            a contact or other request form. We are not responsible for any
            damages or costs of any type arising out of or in any way connected
            with your dealings with these third parties.
            <br />
          </Paragraph>

            <Title>8. Intellectual Property.</Title>
          <Paragraph>
            The Services are owned and operated by the ZappletZ. The user
            interfaces, design, information, data, code, products, software,
            graphics, and all other elements of the Services provided are
            protected by intellectual property and other laws and are the
            property of ZappletZ. Except as expressly allowed by these Terms of
            Use, you may not make use of the ZappletZ &apos; Materials, and ZappletZ 
            reserve all rights to the ZappletZ &apos; Materials and Services not
            granted expressly in these Terms of Use.
          </Paragraph>

            <Title>9. Feedback.</Title>
          <Paragraph>
            Visit <Link href="/support" ><span style={{color:"#6fa56f", cursor:"pointer"}}>contact us</span></Link> to send a
            message.
          </Paragraph>

            <Title>10. Deactivation/Deletion /Changes to Agreement.</Title>
          <Paragraph>
            Except as stated in any Product&apos;sTerms, you may deactivate your
            account at any time by selecting &quot;deactivate account” in your
            account settings. You can delete your account and all of your
            account data by submitting a request to &quot;delete your data” at
            support@ZappletZ.com. If you deactivate or delete your account, you
            remain obligated to pay all outstanding fees, if any, incurred prior
            to termination relating to your use of the Services. If you violate
            any provision of these Terms of Use, your permission from the
            ZappletZ to use the Services will terminate automatically. In
            addition, we may, in our sole discretion, deactivate, suspend, or
            terminate your access to your account and the Services at any time
            for any reason, with or without notice. We may alter, suspend or
            discontinue the Services or any portion of the Services without
            notice. We will not be liable whatsoever for any change to the
            Services or any suspension or termination of your access to, or use
            of the Services. We reserve the right to change these Terms of Use
            at any time in its sole discretion on a going-forward basis. We will
            make commercially reasonable efforts to notify you of any material
            changes to these Terms of Use. Your continued use of the Services
            after any updates are effective will represent your agreement to the
            revised version of the Terms of Use. Your continued use of the
            Services after the effectiveness of such changes will constitute
            acceptance of, and agreement to, any such changes. You further waive
            any right you may have to receive specific notice of such changes to
            these Terms of Use. You are responsible for regularly reviewing
            these Terms of Use.
          </Paragraph>

            <Title>Indemnification.</Title>
          <Paragraph>
            You agree to indemnify, defend, and hold harmless the ZappletZ ,
            their affiliates, and their respective directors, officers,
            employees, and agents from any and all claims and demands made by
            any third party due to or arising out of: (a) your access to or use
            of the Services; (b) your breach of these Terms of Use; (c) your
            violation of any law or the rights of a third party; (d) any dispute
            or issue between you and any third party; (e) any User Materials you
            upload to, or otherwise make available through, the Services; (f)
            your willful misconduct; and (g) any other party&apos;saccess to or use
            of the Services using your account and password. ZappletZ reserve
            the right, at their own expense, to assume the exclusive defense and
            control of any matter otherwise subject to indemnification by you,
            and in that case, you agree to corporate with ZappletZ &apos; defense of
            that claim.
          </Paragraph>

            <Title>No Warranties.</Title>
          <Paragraph>
            ZappletZ  PROVIDE THE SERVICES &quot;AS IS,&quot; &quot;WITH ALL FAULTS&quot; AND &quot;AS
            AVAILABLE,&quot; AND THE ENTIRE RISK AS TO SATISFACTORY QUALITY,
            PERFORMANCE, ACCURACY, AND EFFORT IS WITH YOU. TO THE MAXIMUM EXTENT
            PERMITTED BY APPLICABLE LAW, ZappletZ  AND ITS AFFILIATES MAKE NO
            REPRESENTATIONS, WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED.
            ZappletZ  AND ITS AFFILIATES EXPRESSLY DISCLAIM ANY AND ALL
            WARRANTIES OR CONDITIONS, EXPRESS, STATUTORY AND IMPLIED, INCLUDING
            WITHOUT LIMITATION: (A) WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE EFFORT, ACCURACY,
            TITLE, QUIET ENJOYMENT, NO ENCUMBRANCES, NO LIENS AND
            NON-INFRINGEMENT; (B) WARRANTIES OR CONDITIONS ARISING THROUGH
            COURSE OF DEALING OR USAGE OF TRADE; AND (C) WARRANTIES OR
            CONDITIONS OF UNINTERRUPTED OR ERROR-FREE ACCESS OR USE. NO ADVICE
            OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH THE
            SERVICES OR ANY MATERIALS AVAILABLE THROUGH THE SERVICES WILL CREATE
            ANY WARRANTY REGARDING ANY ZappletZ &apos; ENTITY OR THE SERVICES THAT IS
            NOT EXPRESSIVELY STATED IN THESE TERMS OF USE. YOU ASSUME ALL RISK
            FOR ANY DAMAGE THAT MAY RESULT FROM YOUR USE OF OR ACCESS TO THE
            SERVICES, YOUR DEALING WITH ANY OTHER USER, AND ANY MATERIALS,
            INCLUDING ALL USER AND ZappletZ &apos; MATERIALS, AVAILABLE THROUGH THE
            SERVICES. YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES,
            AND USE, ACCESS, DOWNLOAD, OR OTHERWISE OBTAINMENT OF MATERIALS
            THROUGH THE SERVICES AND ANY ASSOCIATED SITES OR SERVICES, ARE AT
            YOUR OWN DISCRETION AND RISK, AND THAT YOU ARE SOLELY RESPONSIBLE
            FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR
            MOBILE DEVICE USED IN CONNECTION WITH THE SERVICES), OR THE LOSS OF
            DATA THAT RESULTS FROM THE USE OF THE SERVICES OR THE DOWNLOAD OR
            USE OF THOSE MATERIALS. SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER
            OR WARRANTIES AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM
            JURISDICTION TO JURISDICTION.
          </Paragraph>

          <Title>Limitation of Liability/Exclusive Remedy.</Title>
          <Paragraph>
            IN NO EVENT WILL ZappletZ  OR ANY OF THEIR AFFILIATES BE LIABLE FOR
            ANY INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR PUNITIVE
            DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY
            OTHER INTANGIBLE LOSS) ARISING OUT OF, BASED ON, OR RESULTING FROM
            THESE TERMS OF USE OR YOUR USE OR ACCESS, OR INABILITY TO USE OR
            ACCESS, THE SERVICES OR ANY MATERIALS ON THE SERVICES, WHETHER BASED
            ON: (A) BREACH OF CONTRACT; (B) BREACH OF WARRANTY; (C) NEGLIGENCE;
            OR (D) ANY OTHER CAUSE OF ACTION, EVEN IF THE ZappletZ  HAVE BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE MAXIMUM EXTENT
            PERMITTED BY APPLICABLE LAW, ZappletZ  ASSUME NO LIABILITY OR
            RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF
            MATERIALS; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
            WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF THE SERVICES;
            (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR
            ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY
            INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES;
            (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE
            TRANSMITTED TO OR THROUGH OUR SERVICES BY ANY THIRD PARTY; (VI) ANY
            ERRORS OR OMISSIONS IN ANY MATERIALS OR FOR ANY LOSS OR DAMAGE
            INCURRED AS A RESULT OF THE USE OF ANY MATERIALS POSTED, EMAILED,
            TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE SERVICES; OR
            (VII) USER MATERIALS OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL
            CONDUCT OF ANY THIRD PARTY. THE AGGREGATE LIABILITY OF ZappletZ  AND
            ANY OF THEIR AFFILIATES TO YOU FOR ALL CLAIMS ARISING OUT OF OR
            RELATING TO THE USE OF, OR INABILITY TO USE, ANY PORTION OF THE
            SERVICES OR OTHERWISE UNDER THESE TERMS OF USE, WHETHER UNDER
            CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE GREATER OF: (1) THE
            AMOUNT YOU HAVE PAID TO ZappletZ  FOR THE SERVICES IN THE 12 MONTHS
            PRIOR TO THE EVENTS OR CIRCUMSTANCES GIVING RISE TO THE CLAIMS; OR
            (2) $100. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
            LIMITATION OF LIABILITY FOR CONSEQUENTIAL DAMAGES. ACCORDINGLY, THE
            ABOVE LIMITATIONS MAY NOT APPLY TO YOU. EACH PROVISION OF THESE
            TERMS OF USE THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER
            OF WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED TO AND DOES
            ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THESE TERMS. THIS
            ALLOCATION IS AN ESSENTIAL ELEMENT OF THE AGREEMENT OF THE PARTIES.
            THE LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED
            REMEDY FAILS ITS ESSENTIAL PURPOSE.
          </Paragraph>

            {/* <Title>Choice of Law; Disputes.</Title>
          <Paragraph>
            These Terms of Use are governed by the laws of the State of Texas,
            without giving effect to its conflict of laws&apos; provisions. You agree
            to submit to the personal and exclusive jurisdiction and venue in
            the state and federal courts sitting in Collin County, Texas for
            any and all disputes, claims and actions arising from or in
            connection with the Services or otherwise under these Terms of Use.
            ZappletZ operate the Services from its offices in Washington, and we
            make no representation that the Services are appropriate or
            available for use in other locations.
          </Paragraph> */}

          <Title>General.</Title>
          <Paragraph>
            You agree not to export from anywhere any part of the Services
            provided to you, or any direct product thereof, except in compliance
            with, and with all licenses and approvals required under, applicable
            export laws, rules and regulations. All Services used by the U.S.
            Government are provided with the commercial license rights described
            herein. These Terms of Use may only be amended by a written
            agreement signed by authorized representatives of the parties to
            these Terms of Use. If any part of these Terms of Use is determined
            to be invalid or unenforceable, then the invalid or unenforceable
            provision will be replaced with a valid, enforceable provision that
            most closely matches the intent of the original provision and the
            remainder of these Terms of Use will continue in effect. The section
            titles in these Terms of Use are solely used for the convenience of
            the parties and have no legal or contractual significance. We may
            assign this Agreement, in whole or in part, at any time with or
            without notice to you. You may not assign these Terms of Use, or
            assign, transfer or sublicense your rights, if any, in the Services.
            Our failure to act with respect to a breach, or our choice to
            otherwise waive breach, by you or others does not waive its right to
            act with respect to subsequent or similar breaches. Except as
            expressly stated herein, these Terms of Use, and all expressly
            incorporated terms and agreements, constitute the entire agreement
            between you and ZappletZ with respect to the Services and supersede
            all prior or contemporaneous communications of any kind between you
            and ZappletZ with respect to the Services. The following sections of
            these Terms of Use shall survive any termination of these Terms of
            Use: 3, 5-8, 10-12, and 14-22.
          </Paragraph>

          <Title>Consent to Communications.</Title>
          <Paragraph>
            By using the Services, you consent to receiving certain electronic
            communications from us as further described in the Privacy Policy.
            Please read the Privacy Policy to learn more. You agree that any
            notices, agreements, disclosures, surveys, or other communications
            that we send to you electronically will satisfy any legal
            communication requirements, including that those communications be
            in writing. The Services may provide web forms, links, or contact
            information, including phone numbers, that can connect you with
            ZappletZ or third parties. Communications through these methods may be
            routed through a third-party service (&quot;Communications Service&quot;).
            Calls may be recorded or monitored for quality assurance, training,
            or customer service purposes. You will be notified at the beginning
            of a call if it may be recorded or monitored. You consent to such
            recording and monitoring by ZappletZ or the Communications Service.
            As part of this process, we and the Communications Service
            will receive in real time, and store, data about your call or text
            message, including the date and time of the call or text message,
            your phone number, and the content of the text message. You consent
            to these practices by us and the Communications Service. The
            information is subject to the Privacy Policy.
          </Paragraph>

          <Title>Refund Policy</Title>
          <Paragraph>
            Unless otherwise specified, all fees paid are non-refundable. No refunds will be provided for unused portions of any service.
          </Paragraph>

          <Title>BINDING ARBITRATION AGREEMENT AND CLASS ACTION WAIVER</Title>
          <Paragraph>
          Please read this section carefully – it may significantly affect your legal rights, including your right to file a lawsuit in court and to have a jury hear your claims.

          By using the Services, you irrevocably agree: (a) to waive all rights to trial in a court before a judge or jury on any claim, action or dispute with us or relating in any way to your use of the Services or the interpretation, applicability, enforceability or formation of these Terms of Use including, but not limited to, any claim that all or any part of this agreement is void or voidable (&quot;Claims”); (b) that all Claims will be determined exclusively by final and binding arbitration in Orange County, California before one arbitrator; and (c) that the arbitrator will not have the authority to consolidate the Claims of other users of the Services (&quot;Users”) and will not have the authority to fashion a proceeding as a class or collective action or to award relief to a group or class of Users in one arbitration proceeding.

          The arbitration shall be administered by JAMS pursuant to its Comprehensive Arbitration Rules and Procedures and in accordance with the Expedited Procedures in those Rules. Notwithstanding these rules, however, such proceeding shall be governed by the laws of the State of California, Orange County. All parties shall maintain the confidential nature of the arbitration proceeding and the award, including the hearing, except as may be necessary to prepare for or conduct the arbitration hearing on the merits, or except as may be necessary in connection with a court application for a preliminary remedy, a judicial challenge to an award or its enforcement, or unless otherwise required by law or judicial decision.

          The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve any Claims. However, nothing in this section shall prevent us from enforcing our intellectual property rights and/or remedy unfair competition, misappropriation of trade secrets, unauthorized access, fraud or computer fraud, and/or industrial espionage in court.

          Judgment on any arbitration award may be entered in any court having jurisdiction. In any arbitration arising out of or related to these Terms of Use, the arbitrator shall award to the prevailing party, if any, the costs and attorneys’ fees reasonably incurred by the prevailing party in connection with the arbitration. If the arbitrator determines a party to be a prevailing party under circumstances where the prevailing party won on some but not all of the claims and counterclaims, the arbitrator may award the prevailing party an appropriate percentage of the costs and attorneys’ fees reasonably incurred by the prevailing party in connection with the arbitration.
          </Paragraph>


            <Title>Privacy Policy</Title>
          <Paragraph>
            Please read{" "}
            <Link href="/privacy-policy" ><span>Privacy Policy</span></Link>
          </Paragraph>

          <Title>Updated June 01, 2024</Title>
        </Section>
      </MainContainer>
    </>
  )
};

const Link = styled.a`
    text-decoration: none;
    color: #4B9FE1;
    cursor:pointer;
`;

const MainContainer = styled.div`
  display: flex;
  margin: 15px;
  @media (max-width: 770px) {
    flex-direction: column;
  }
`;

const Section = styled.div`
  flex: 3;
  padding: 25px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  font-size: 32px;
  font-weight: 700;
  flex-direction: column;
  text-align: center;
  width: 100%;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

const Paragraph = styled.p`
  line-height: 2;
`;

export default Terms;
