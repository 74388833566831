import axios from 'axios';

import { userContext } from '../usercontext';

export const fetchWrapper = {
    post,
    get,
    getFile,
    authHeader,
    postFile,
};

function get(url: any) {
    const requestOptions: any = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        responseType: 'stream'
    };
    return fetch(url, requestOptions).then(handleResponse)
}

function getFile(url: any) {
    const requestOptions: any = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        responseType: 'stream'
    };
    return fetch(url, requestOptions).then(handleFileResponse)
}

function post(url: any, body: any) {

    const requestOptions:any = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        credentials: 'include',
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse)
}

function postFile(url: any, body: any) {
    const requestOptions:any = {
        method: 'POST',
        // headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        headers:  authHeader(url) ,
        credentials: 'include',
        body: body,
    };
    return fetch(url, requestOptions).then(handleResponse)
}

// helper functions

function authHeader(url: any) {
    // return auth header with jwt if user is logged in and request is to the api url
    const user: any = userContext.userValue;
    if(url.includes("/api/admin/")){
        if(user.role!=="admin747"){
            console.log(`Unauthorized user not admin`)
            return {}
        }
    }
    const isLoggedIn = user && user.token;
    if (isLoggedIn) {
        return { Authorization: `Bearer ${user.token}` };
    } else {
        return {};
    }
}

function handleFileResponse(response: any) {
    if(response.status!==200){
        return Promise.reject('File download error')
    }
    return response;
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text)
        
        
        if (!response.ok) {
            if ([401, 403].includes(response.status) && userContext.userValue) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                userContext.logout()
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error)
        }
        return data;
    })
}