import React, { useState, useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components';
import Button from '../components/Button';
import { MdCheck } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

interface Prop{
  history?: any
}
const Home : React.FC<Prop>=(prop)=>{
  const history = useHistory()
  
  const [ show, setshow ] = useState(false)

  const midDiv = useRef<HTMLDivElement>(null);
  const executeScroll = () => {
    midDiv.current?.scrollIntoView({ behavior: "smooth" });
  };
  
  return(
    <MainContainer>
      <Column>
          <Header style={{color:"#000"}}>ZappletZ</Header>
          <Row>
            {/* <Column style={{ alignItems: 'flex-start'}}>
				<SubHeader style={{color:"#000"}}>Email Marketing</SubHeader>

				<CaptionItem><CaptionItem style={{width:"60px"}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem><CaptionItem >No hassel email marketing</CaptionItem></CaptionItem>
				<CaptionItem><CaptionItem style={{width:"60px"}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem><CaptionItem >Automate email campaigns</CaptionItem></CaptionItem>
				<CaptionItem><CaptionItem style={{width:"60px"}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem><CaptionItem >Track  email delivery on dashboard</CaptionItem></CaptionItem>
				<CaptionItem><CaptionItem style={{width:"60px"}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem><CaptionItem >Send 500 emails free with basic account</CaptionItem></CaptionItem>
				<CaptionItem><CaptionItem style={{width:"60px"}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem><CaptionItem >Flexible pricing based on usage</CaptionItem></CaptionItem>
            </Column> */}
			<Column style={{ alignItems: 'flex-start'}}>
				<SubHeader style={{color:"#000"}}>Password Manager</SubHeader>

				<CaptionItem><CaptionItem style={{width:"60px"}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem><CaptionItem > Never lose your passwords</CaptionItem></CaptionItem>
				<CaptionItem><CaptionItem style={{width:"60px"}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem><CaptionItem > Access your passwords anywhere</CaptionItem></CaptionItem>
				<CaptionItem><CaptionItem style={{width:"60px"}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem><CaptionItem > Generate strong passwords</CaptionItem></CaptionItem>
				<CaptionItem><CaptionItem style={{width:"60px"}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem><CaptionItem > Save up to 100 passwords for free</CaptionItem></CaptionItem>
				<CaptionItem><CaptionItem style={{width:"60px"}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem><CaptionItem > Backup your passwords</CaptionItem></CaptionItem>
            </Column>
          </Row>
            <ButtonContainer>
                <Button text="Register" handleClick={()=>prop.history.push("/register")}/>
                <Button text="Password Manager" handleClick={()=>prop.history.push("/password-manager")}/>
            </ButtonContainer>
            <ArrowDownDiv onClick={executeScroll}>
              <DownArrow> </DownArrow>
            </ArrowDownDiv>
          <br/>
          <br/>

          </Column>
          <ColumnHowtouse ref={midDiv}>
            <SubHeader>Store passwords securely</SubHeader>
            <ul>
            <LI>Register an account with your email and password. This password is only for logging into zappletz.com.</LI>
            <LI>To save information, go to the profile page and generate encryption keys. Choose a strong "Passphrase."</LI>
            <LI>The "Passphrase" is the only information you need to remember. It is not stored anywhere.</LI>
            <LI>If you forget or lose your passphrase, all your stored information will be lost.</LI>
            <LI>You can reset your passphrase by generating new keys, but you will lose your previously saved information.</LI>
            <LI>To save passwords click on the Save menu enter your infomation and save.</LI>
            <LI>To retrieve passwords, click on the Retrieve menu, enter your passphrase, and press enter</LI>
            </ul>
			</ColumnHowtouse>

          <br/>
          <br/>
          <SubHeader>PDF Signing and Editing APP</SubHeader>

			<Column style={{ alignItems: 'flex-start'}}>
            <CaptionItem><CaptionItem style={{width:"60px"}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem>Edit PDF documents</CaptionItem>
            <CaptionItem><CaptionItem style={{width:"60px"}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem>Sign Documents</CaptionItem>
            <CaptionItem><CaptionItem style={{width:"60px"}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem>Merge PDF Document</CaptionItem>
            <CaptionItem><CaptionItem style={{width:"60px"}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem>Split Document</CaptionItem>
			</Column>
		<Button text="PDF APP" handleClick={()=>history.push("/apps/pdf-app")} />

		<br/>
		<br/>
		<SubHeader>Photo Editing APP</SubHeader>

			<Column style={{ alignItems: 'flex-start'}}>
				<CaptionItem><CaptionItem style={{width:"60px"}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem>Resize and Crop images</CaptionItem>
				<CaptionItem><CaptionItem style={{width:"60px"}}><MdCheck color='#8D56DC' fontSize={40} /></CaptionItem>Make printable passport images </CaptionItem>
			</Column>
		<Button text="PDF APP" handleClick={()=>history.push("/apps/photo-app")} />

      {/* <AIChat 
        userid="ULWHH"
        supportEmail="support@miamigo.ai"
        supportPhone="555-555-5555"
        chatbotname="Chatbot"
        title='AI Chatbot'
        handleQuestion={(q: any)=>handlequestion(q)}
      /> */}
    </MainContainer>
  );
}

const arrowAnimation = keyframes`
    0% { -webkit-transform: scale(1); }
    50% { -webkit-transform: scale(1.05);}
    100% { -webkit-transform: scale(1);}
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  justify-content: center;
  align-items: center;
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Column = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #150734;
  // width: min(500px, 90%);
  @media (max-width: 770px) {
    padding: 25px 50px;
  }
`

const ColumnHowtouse = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #150734;
  width: min(500px, 90%);
  @media (max-width: 770px) {
    padding: 25px 50px;
  }
`
const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  // flex: 1;
  color: #150734;
  font-size: 70px;
  font-weight: 900;
  padding: 10px;
  text-align: center;
  @media (max-width: 770px) {
    font-size: 3rem;
  }
`;

const SubHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: #150734;
  font-size: 24px;
  font-weight: 900;
  padding: 10px;
  text-align: center;
  @media (max-width: 770px) {
    font-size: 3rem;
  }
`;

const CaptionItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  padding: 10px;
`;

const LI = styled.li`
  padding: 7px;
  font-size: 18px;
`;

const ArrowDownDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 10px;
  // width: 80%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  @media (min-width: 770px) {
    display: none;
  }
`;

const DownArrow = styled.div`
  border: solid #8D56DC;
  border-width: 0 10px 10px 0;
  border-radius: 3px;
  padding: 15px;
  opacity: 1;
  animation: ${arrowAnimation} 1s infinite linear;
}`;

export default Home;