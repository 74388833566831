import React, {useState, useEffect, useRef} from 'react'
import styled, { keyframes } from 'styled-components'
import Button from '../SmallButton';
import HorizontalLine from '../horizontalLine';
import download from 'downloadjs';
import InputComp from '../InputComp';
import SelectComp from '../SelectComp';
import IncrementDecrementComp from '../IncrementDecrementComp';
import { PDFDocument, rgb, StandardFonts, PageSizes } from 'pdf-lib'
import { DrawText } from '../../utils/helperfunctions';

interface Prop{
    openModal: (h: any, m: any, p?: any)=>void;
}
const EditSignPDFDoc : React.FC<Prop>=({openModal})=>{
    const [fileUrl, setFileUrl] = useState<any>(null);
    const [signatureUrl, setSignatureUrl] = useState(null);
    const iframeRef = useRef<any>(null);
    const canvasRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [ allowsign, setallowsign ] = useState(false)
    // const [ xpos, setxpos ] = useState(0)
    // const [ ypos, setypos ] = useState(0)
    const [ pdfwidth, setpdfwidth ] = useState(600)
    const [ pdfheight, setpdfheight ] = useState(800)

    const [ selectedpageurl, setselectedpageurl ] = useState<any>(null)
    const [ totalnumberofPages, settotalnumberofPages ] = useState(0)
    
    const [ selectedpagenumber, setselectedpagenumber ] = useState(0)
    const [ filename, setfilename ] = useState("")

    const colors = [
        rgb(0,0,0),
        rgb(0,0,0),
        rgb(1,0,0),
        rgb(0,1,0),
        rgb(0,0,1),
        rgb(1,1,1),
    ]
    const colornames = [
        "-select color-", "black","red", "green", "blue", "white"
    ]

    const [ editing, setediting ] = useState(false)
    const [ text, settext ] = useState("")
    const [ fontsize, setfontsize ] = useState(20)
    const [ selectedcoloroptionid, setselectedcoloroptionid ] = useState(0)
	
    
    const handleFileChange = async (event: any) => {
        const selectedFile = event.target.files[0];
		try{
			let canvas: any = canvasRef.current
			canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height); // Clear canvas for new signature
		}catch(e){

		}

        if (selectedFile && selectedFile.type === "application/pdf") {
			setfilename(selectedFile.name)
			let selectedpagenumber = 0
            const fileURL = URL.createObjectURL(selectedFile);
            setFileUrl(fileURL);

            const response = await fetch(fileURL);
            const blob = await response.blob();
            let fileblob = await blob.arrayBuffer();
            var pdfDoc = await PDFDocument.load(fileblob);
            let totalnumberofPages = pdfDoc.getPageCount()
            settotalnumberofPages(totalnumberofPages)
			setselectedpagenumber(0)
            const { width: pdfWidth, height: pdfHeight } = pdfDoc.getPages()[selectedpagenumber].getSize();
            const page = pdfDoc.getPages()[selectedpagenumber]
            const singlepdfDoc = await PDFDocument.create()
            let copiedPages = await singlepdfDoc.copyPages(pdfDoc, [selectedpagenumber])
            const [copiedpage] = copiedPages;
            singlepdfDoc.insertPage(0, copiedpage)
            const selectedpageurl: any = await singlepdfDoc.saveAsBase64({ dataUri: true });
            setselectedpageurl(selectedpageurl)
            setpdfwidth(pdfWidth)
            setpdfheight(pdfHeight)
        } else {
            openModal("Warning", "Please upload a valid PDF file.");
			setfilename("")
            settotalnumberofPages(0)
			setselectedpagenumber(0)
            setselectedpageurl("")
            setpdfwidth(0)
            setpdfheight(0)
        }
    }

	const clearPage = async () =>{
        if(allowsign){
		    const canvas: any = canvasRef.current;
            canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height); // Clear canvas for new signature
        }else{
            movepageto(0)
        }

	}

	const showprevpage = async () => {
		if(selectedpagenumber==0){
			return
		}

		await movepageto(-1)
	}

	const shownextpage = async () => {
		if(selectedpagenumber+1==totalnumberofPages){
			return
		}
		await movepageto(1)
	}

	const movepageto = async (movecounter :any )=>{
		if(allowsign||editing){
			openModal("Warning", "Finish Signing before proceeding", false)
			return
		}
		const response = await fetch(fileUrl);
		const blob = await response.blob();
		let fileblob = await blob.arrayBuffer();
		var pdfDoc = await PDFDocument.load(fileblob);

		const { width: pdfWidth, height: pdfHeight } = pdfDoc.getPages()[selectedpagenumber+movecounter].getSize();
		// const page = pdfDoc.getPages()[selectedpagenumber+movecounter]
		const singlepdfDoc = await PDFDocument.create()
		let copiedPages = await singlepdfDoc.copyPages(pdfDoc, [selectedpagenumber+movecounter])
		const [copiedpage] = copiedPages;
		singlepdfDoc.insertPage(0, copiedpage)
		const selectedpageurl: any = await singlepdfDoc.saveAsBase64({ dataUri: true });
		setselectedpageurl(selectedpageurl)
		setpdfwidth(pdfWidth)
		setpdfheight(pdfHeight)
		setselectedpagenumber(selectedpagenumber+movecounter)
	}

	const downloadDoc = async () => {
		if(allowsign || editing){
			openModal("Warning", "Changes not saved click Finish before proceeding", false)
			return
		}

        const response = await fetch(fileUrl);
        const blob = await response.blob();
        let fileblob = await blob.arrayBuffer();
        var pdfDoc = await PDFDocument.load(fileblob);
        let pdfbytes = await pdfDoc.save()
		download(pdfbytes, `zappletz_${filename}`)
		// download(fileUrl, `zappletz_${filename}`)
	}

    const startDrawing = async (event: any) => {
        if(allowsign){
            setIsDrawing(true);
            event.preventDefault();
            const canvas: any = canvasRef.current;
            const ctx = canvas.getContext("2d");
            const rect = canvas.getBoundingClientRect();
            ctx.beginPath();
            ctx.moveTo(event.clientX-rect.left, event.clientY-rect.top);
        }else{
            await handleeditclick(event)
        }
    };

    const startTouchDrawing = async (event: any) => {
        if(allowsign){
            setIsDrawing(true);
            event.preventDefault();
            const canvas: any = canvasRef.current;
            const ctx = canvas.getContext("2d");
            const rect = canvas.getBoundingClientRect();
            ctx.beginPath();
            ctx.moveTo(event.touches[0].clientX-rect.left, event.touches[0].clientY-rect.top);
        }else{
            await handleTouch(event)
        }
    };

    const draw = (event: any) => {
        if (!isDrawing) return;
        event.preventDefault();
        const canvas: any = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const rect = canvas.getBoundingClientRect();
        // ctx.lineTo(event.clientX, event.clientY);
        ctx.lineTo(event.clientX - rect.left, event.clientY - rect.top);
        ctx.stroke();
    };

    const touchdraw = (event: any) => {
        if (!isDrawing) return;
        event.preventDefault();
        const canvas: any = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const rect = canvas.getBoundingClientRect();
        // ctx.lineTo(event.clientX, event.clientY);
        ctx.lineTo(event.touches[0].clientX - rect.left, event.touches[0].clientY - rect.top);
        ctx.stroke();
    };

    const stopDrawing = async (event: any) => {
        setIsDrawing(false)
        event.preventDefault();
        try{
            // Save the signature as a URL
            const canvas: any = canvasRef.current;
            const signature = canvas.toDataURL("image/png");
            setSignatureUrl(signature);
        }catch(error: any){
            console.log(`Error stopping: ${error}`)
            
        }
    };

    const saveSignatureToPdf = async () => {
        if (!fileUrl) {
            openModal("Warning", "Please upload a PDF first.");
            return;
        }
        
        let response = await fetch(selectedpageurl);
        let blob = await response.blob();
        let fileblob = await blob.arrayBuffer();
        var pdfDoc_onepage = await PDFDocument.load(fileblob);

        // Get the signature as an image
        const canvas: any = canvasRef.current;
        const imageBytes = canvas.toDataURL("image/png");

        // Embed the signature image into the PDF
        const pngImage = await pdfDoc_onepage.embedPng(imageBytes);

        const singlepage = pdfDoc_onepage.getPages()[0];
      
        // Draw the image at the bottom-right corner
        singlepage.drawImage(pngImage, {
            x: 0, // Adjust for image size
            y: 0, // Adjust for image size
            width: pdfwidth,   // Signature width
            height: pdfheight,   // Signature height
        });

        // Serialize the PDF to bytes
        const pdfDataUri: any = await pdfDoc_onepage.saveAsBase64({ dataUri: true });
        setselectedpageurl(pdfDataUri)

		response = await fetch(fileUrl);
        blob = await response.blob();
        fileblob = await blob.arrayBuffer();
        var pdfDoc_file = await PDFDocument.load(fileblob);

		pdfDoc_file.removePage(selectedpagenumber)
		let copiedPages = await pdfDoc_file.copyPages(pdfDoc_onepage, [0])
		const [copiedpage] = copiedPages;
		pdfDoc_file.insertPage(selectedpagenumber, copiedpage)
		const fileurl: any = await pdfDoc_file.saveAsBase64({ dataUri: true });
        setFileUrl(fileurl)

        canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height); // Clear canvas for new signature
    };

	const enablesign = async ()=>{
		if(allowsign){
			await saveSignatureToPdf()
		}
		setallowsign(!allowsign)
        setediting(false)
	}

    const enableedit = async ()=>{
		if(editing){
			await savePdf()
		}
		setediting(!editing)
        setallowsign(false)
	}

    const savePdf = async () => {
        if (!fileUrl) {
            openModal("Warning", "Please upload a PDF first.");
            return;
        }
        
        let response = await fetch(selectedpageurl);
        let blob = await response.blob();
        let fileblob = await blob.arrayBuffer();
        var pdfDoc_onepage = await PDFDocument.load(fileblob);

        // Get the signature as an image
        const singlepage = pdfDoc_onepage.getPages()[0];

        // Serialize the PDF to bytes
        const pdfDataUri: any = await pdfDoc_onepage.saveAsBase64({ dataUri: true });
        setselectedpageurl(pdfDataUri)

        response = await fetch(fileUrl);
        blob = await response.blob();
        fileblob = await blob.arrayBuffer();
        var pdfDoc_file = await PDFDocument.load(fileblob);

        pdfDoc_file.removePage(selectedpagenumber)
        let copiedPages = await pdfDoc_file.copyPages(pdfDoc_onepage, [0])
        const [copiedpage] = copiedPages;
        pdfDoc_file.insertPage(selectedpagenumber, copiedpage)
        const fileurl: any = await pdfDoc_file.saveAsBase64({ dataUri: true });
        setFileUrl(fileurl)

    };

    const handleTouch = async (event: any) => {
        if(!editing){
            return
        }
        if(text=="")return
        event.preventDefault();
        const canvas: any = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const rect = canvas.getBoundingClientRect();
        ctx.beginPath();
        ctx.moveTo(event.touches[0].clientX-rect.left, event.touches[0].clientY-rect.top);
        let xpos = event.touches[0].clientX-rect.left
        let ypos = event.touches[0].clientY-rect.top
        let response = await fetch(selectedpageurl);
        let blob = await response.blob();
        let fileblob = await blob.arrayBuffer();
        var pdfDoc_onepage = await PDFDocument.load(fileblob);
        const singlepage = pdfDoc_onepage.getPages()[0];
        let height = singlepage.getHeight()
        ypos = height-ypos
        // Draw the image at the bottom-right corner
        const timesroman = await pdfDoc_onepage.embedFont(StandardFonts.TimesRoman)

        DrawText(singlepage, timesroman, text, xpos, ypos, fontsize, colors[selectedcoloroptionid])
        const pdfDataUri = await pdfDoc_onepage.saveAsBase64({ dataUri: true });
        setselectedpageurl(pdfDataUri)
        settext("")
    }
    
    const upfunc = () =>{
        setfontsize(fontsize+1)
    }

    const downfunc = () =>{
        if(fontsize==1) return
        setfontsize(fontsize-1)
    }

    const changecolor = (e: any) => {
        setselectedcoloroptionid(e.target.value)
    }

    const handleeditclick = async (event: any) => {
        if(!editing){
            return
        }
        if(text=="")return
        const canvas: any = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const rect = canvas.getBoundingClientRect();
        ctx.beginPath();
        ctx.moveTo(event.clientX-rect.left, event.clientY-rect.top);
        let xpos = event.clientX-rect.left
        let ypos = event.clientY-rect.top
        let response = await fetch(selectedpageurl);
        let blob = await response.blob();
        let fileblob = await blob.arrayBuffer();
        var pdfDoc_onepage = await PDFDocument.load(fileblob);
        const singlepage = pdfDoc_onepage.getPages()[0];
        let height = singlepage.getHeight()
        ypos = height-ypos
        // Draw the image at the bottom-right corner
        const timesroman = await pdfDoc_onepage.embedFont(StandardFonts.TimesRoman)

        DrawText(singlepage, timesroman, text, xpos, ypos, fontsize, colors[selectedcoloroptionid])
        const pdfDataUri = await pdfDoc_onepage.saveAsBase64({ dataUri: true });
        setselectedpageurl(pdfDataUri)
        settext("")
    }

	useEffect(()=>{

	}, [allowsign, selectedpageurl])

	useEffect(() => {

        window.scrollTo(0,0)

        // const canvas: any = canvasRef.current;
        // if (canvas) {
        //     // Add touch event listeners
        //     canvas.addEventListener('touchstart', startTouchDrawing, { passive: false });
        //     canvas.addEventListener('touchmove', touchdraw, { passive: false });
        //     canvas.addEventListener('touchend', stopDrawing, { passive: false });

        //     // Cleanup on unmount
        //     return () => {
        //         canvas.removeEventListener('touchstart', startTouchDrawing);
        //         canvas.removeEventListener('touchmove', touchdraw);
        //         canvas.removeEventListener('touchend', stopDrawing);
        //     };
        // }

	}, []);

  
  return (
    <MainContainer>
		<ul style={{lineHeight:1.5}}>
			<li>Select PDF.</li>
			<li>Click the Edit or Sign.</li>
			<li>Click Finish to Finalize Edit and Signature.</li>
			<li>Click Next or Previous for other Pages.</li>
			<li>Download the Edited Document.</li>
		</ul>
		{/* <input type="file" accept="application/pdf" onChange={handleFileChange} /> */}
        <Row>
			<Button text={!editing?'edit':'finish'} handleClick={enableedit} backgroundColor={editing?"#e2d4f6":""} />
			<Button text={!allowsign?'sign':'finish'} handleClick={enablesign} backgroundColor={allowsign?"#e2d4f6":""} />
			<Button text={"Clear"} handleClick={clearPage} />
			<Button text={"Previous"} handleClick={showprevpage} />
			<Button text={"Next"} handleClick={shownextpage} />
			<Button text={"Download"} handleClick={downloadDoc} />
		</Row>
        {selectedpageurl &&
		<HorizontalLine />}

        {selectedpageurl && editing &&
        <Row>
		    <InputComp  id={`input_id`} value={text} onChange={(e)=>settext(e.target.value)} placeholder='Enter text to insert' />
            <span style={{display: 'flex'}}>
                <InputComp  id={`input_id`} value={`${fontsize}`} onChange={(e)=>{}} placeholder='font size' width={50} />
                <IncrementDecrementComp upfunc={upfunc} downfunc={downfunc}/>
            </span>
            <SelectComp id="colorselect" labels={colornames} onChange={changecolor} placeholder='select color' optionid={selectedcoloroptionid} width={150}/>
        </Row>
        }


		<Row>
			<Label htmlFor="uploadfileinfo"> 
			Select PDF File
			</Label>
			<UploadInput
				id="uploadfileinfo"
				type="file"
				accept="application/pdf" 
				onChange={handleFileChange}
			/>
		</Row>

		<br/>

        {selectedpageurl &&
		<SubHeader>Page: {selectedpagenumber+1}/{totalnumberofPages}</SubHeader>}
		<br/>
        {selectedpageurl && (
        <div style={{ position: "relative",  padding:0, margin: 0}}>
          <iframe
            id = "iframe_id"
            ref={iframeRef}
            src={`${selectedpageurl}#toolbar=0&scrollbar=0&view=Fit`}
            title="PDF Preview"
            style={{
				width:`${pdfwidth}px`,
            	height:`${pdfheight}px`,
              	zIndex:allowsign?1:100, border: "none",
				overflow:'hidden',
				pointerEvents:"none",
			}}
          />
            <canvas
                ref={canvasRef}
                style={{
                    position: "absolute",
                    top:0,
                    left: 0,
                    cursor: "pointer",
                    zIndex:allowsign?100:1,
                    pointerEvents:editing?"all":!allowsign?"none":"all",
                    backgroundColor:"rgba(0, 0, 0, 0.05)",
                    touchAction: "none"
                }}
                
                width={`${pdfwidth}px`}
                height={`${pdfheight}px`}
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={stopDrawing}
                onMouseLeave={stopDrawing}
                onTouchStart={startTouchDrawing}
                onTouchMove={touchdraw}
                onTouchEnd={stopDrawing}
				
            />
        </div>
      )}
    </MainContainer>
  );
};


const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    // min-height: 150vh;
    width: 100%;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    width: 100%;
`;
const Row = styled.div`
    display: flex;
	flex-wrap: wrap;
`;
const Header = styled.div`
    font-size: 30px;
    font-weight: 700;
`;
const SubHeader = styled.div`
    font-size: 24px;
    font-weight: 500;
`;

const UploadInput = styled.input`
  margin: 5px;
  text-align: center;
  opacity: 0;
  display: none;
`;

const Label = styled.label`
  color: #4B9FE1;
  margin: 5px;
  background-color: #eee;
  font-size: 16px;
  flex-wrap: wrap;
  padding: 7px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
`;

export default EditSignPDFDoc