import React, {useState, useEffect} from 'react'
import styled, { keyframes } from 'styled-components'
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';

const blogs = [
	{
		title: "Document Manager",
		url: "/apps/pdf-app",
	},
	{
		title: "Photo Editor",
		url: "/apps/photo-app",
	},
  {
		title: "Text 2 Voice",
		url: "/apps/ztts-app",
	},
  {
		title: "Video Editor",
		url: "/apps/video-app",
	},

];

interface Prop{
}
const Apps : React.FC<Prop>=({})=>{
    const history = useHistory()
  return(
    <MainContainer>
            <Column>
                <Header>Apps by ZappletZ</Header>
                <BlogTiles>
                    {blogs?.map((value, indx) => (
                      <Button text={`${value.title}`} handleClick={()=>history.push(value.url)} width={50} />
                    // <BlogTile key={`blog${indx}`}>
                    //     <BlogTileDiv
                    //     key={indx}
                    //     onClick={() => {
                    //         history.push(value.url);
                    //     }}
                    //     >
                    //     </BlogTileDiv>
                    //     {value.title}
                    // </BlogTile>
                    ))}
          </BlogTiles>  
            </Column>
    </MainContainer>
  );
}

const Image = styled.img`
  // &:hover{
  //   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25)
  // }
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 85vh;
    justify-content: flex-start;
    align-items: center;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
`;
const Header = styled.div`
    font-size: 35px;
    font-weight: 700;
    margin: 20px;
`;

const BlogTiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  justify-content: space-around;
`;

const BlogTile = styled.div`
  margin: 25px 25px 35px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const BlogTileDiv = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 200px;
  border-radius: 10px;
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  box-shadow: -4px 10px 35px -1px rgba(0, 0, 0, 0.75);
  text-align: center;
  color: #000;
  flex-wrap: wrap;
  cursor: pointer;
  transition: transform 0.25s;
  &:hover {
    transform: scale(1.05);
  }
`;


export default Apps